var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.goods.last_stocktaking_datetime ? _c('van-cell', {
    attrs: {
      "title": "最后盘点时间",
      "value": _vm.goods.last_stocktaking_datetime
    }
  }) : _vm._e(), _vm.goods.stocktaking_user ? _c('van-cell', {
    attrs: {
      "title": "正在盘点",
      "value": _vm.goods.stocktaking_user.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "goods-info-container"
  }, [_c('div', {
    staticClass: "goods-info-container__left"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.product.image
    }
  })], 1), _c('div', {
    staticClass: "goods-info-container__right"
  }, [_c('div', {
    staticClass: "goods-name"
  }, [_vm._v(" " + _vm._s(_vm.goods.product.name) + " ")]), _c('div', {
    staticClass: "size"
  }, [_vm._v(" 规格：1 * " + _vm._s(_vm.goods.product.size) + " ")]), _c('div', {
    staticClass: "storage"
  }, [_vm._v(" 储位：" + _vm._s(_vm.goods.default_in_warehouse ? _vm.goods.default_in_warehouse.storage : '') + " ")]), _c('div', {
    staticClass: "stock",
    class: {
      stock_warning: _vm.goods.book_stock <= _vm.goods.stock_warning
    }
  }, [_vm._v(" 库存：" + _vm._s(_vm.goods.book_stock) + _vm._s(_vm.goods.product.least_unit) + " ")])])]), _vm.canShowPrice ? _c('van-cell', {
    staticClass: "price-cell",
    attrs: {
      "title": "查看价格",
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.goods.show_price = !_vm.goods.show_price;
      }
    },
    scopedSlots: _vm._u([_vm.goods.show_price ? {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v(" 来货价：￥" + _vm._s(_vm.goods.product.purchase_price) + "、 批发价：￥" + _vm._s(_vm.goods.product.whole_price) + "、 零售价：￥" + _vm._s(_vm.goods.product.bulk_price) + "、 优惠价：￥" + _vm._s(_vm.goods.product.prefrt_price))])];
      },
      proxy: true
    } : null], null, true)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }