<template>
  <div class="warehouse-goods-container">
    <my-nav-bar
      title="仓库商品"
      left-text="返回"
      left-arrow
      :right-text="$route.query.category_id ? '' : '筛选'"
      @click-right="$route.query.category_id ? () => {} : handleFilter()"
    />
    <div class="app-search-bar">
      <form action="/">
        <van-search
          v-model="listQuery.name"
          placeholder="请输入商品名称"
          clearable
          @search="handleSearch"
        />
      </form>
    </div>
    <div class="app-main-search-container list-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="getGoodses"
      >
        <div v-for="(goods, index) in goodses" :key="index" class="goods-container">
          <warehouse-goods-item
            :goods="goods"
            @click="toDetailView(goods)"
          />
        </div>
      </van-list>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="selectAction" />
    <my-dialog v-model="showFilterView" title="筛选" @confirm="filter">
      <van-checkbox-group v-model="listQuery.types" class="check-group">
        <van-checkbox class="checkbox" :name="1">隐藏禁止销售的</van-checkbox>
        <van-checkbox class="checkbox" :name="2">显示没储位的</van-checkbox>
        <van-checkbox :name="3">显示正在盘点的</van-checkbox>
      </van-checkbox-group>
    </my-dialog>
  </div>
</template>

<script>
import warehouseGoodsApi from '@/api/warehouse_goods'
import WarehouseGoodsItem from './components/goods'
import MyNavBar from '../../components/my-nav-bar.vue'
import MyDialog from '../../components/my-dialog.vue'
export default {
  name: 'WarehouseGoods',
  components: { WarehouseGoodsItem, MyNavBar, MyDialog },
  data() {
    return {
      listQuery: {
        name: '',
        types: [1],
        page: 0,
        limit: 10
      },
      goodses: [],
      loading: false,
      finished: false,
      showFilterView: false,
      showActionSheet: false,
      showEmpty: false,
      actions: [
        { type: 1, name: '分类筛选' },
        { type: 2, name: '其他筛选' }
      ]
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
    // 监听回传数据
    this.$eventBus.$on('update-stock', (res) => {
      const goods = this.goodses.find(goods => goods.id === Number(res.goods_id))
      if (goods) { goods.book_stock = res.book_stock }
    })
  },
  activated() {
    // window.pageYOffset = this.$route.meta.scrollTop
    document.documentElement.scrollTop = this.$route.meta.scrollTop
    // document.body.scrollTop = this.$route.meta.scrollTop
    // const filterCateogry = this.$store.state.filterCategory
    // if (filterCateogry && (!this.listQuery.category_id || this.listQuery.category_id !== filterCateogry.id)) {
    if (this.$route.query.category_id) {
      if (this.listQuery.category_id !== this.$route.query.category_id) {
        this.listQuery.category_id = this.$route.query.category_id
        this.resetList()
      }
    } else {
      if (this.listQuery.category_id) {
        this.listQuery.category_id = null
        this.resetList()
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/warehouse-goods-detail' && to.path !== '/warehouse-goods-filter') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      // 取消监听
      this.$eventBus.$off('update-stock')
    }
    next()
  },
  methods: {
    handleSearch() {
      this.listQuery.page = 0
      this.finished = false
      this.goodses = []
      this.getGoodses()
    },
    getGoodses() {
      this.listQuery.page++
      warehouseGoodsApi.list(this.listQuery).then(res => {
        this.goodses = this.goodses.concat(res.data.list)
        this.loading = false
        if (res.data.list.length < this.listQuery.limit) {
          this.finished = true
        }
        this.showEmpty = this.goodses.length === 0
      })
    },
    toDetailView(goods) {
      this.$router.push({
        path: '/warehouse-goods-detail',
        query: {
          goods_id: goods.id
        }
      })
    },
    selectAction(action) {
      this.showActionSheet = false
      if (action.type === 1) {
        // 分类筛选
        this.$nextTick(() => {
          this.$router.push('/warehouse-goods-filter')
        })
      } else {
        // 其他筛选
        this.showFilterView = true
      }
    },
    handleFilter() {
      this.showActionSheet = true
    },
    resetList() {
      this.listQuery.page = 0
      this.goodses = []
      this.loading = true
      this.finished = false
      this.getGoodses()
    },
    filter() {
      this.showFilterView = false
      this.resetList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-container {
    padding: 15px 15px 5px 15px;
  }
  .goods-container {
    margin-bottom: 10px;
  }
  .check-group {
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .checkbox {
      margin-bottom: 5px;
    }
  }
</style>
