var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "warehouse-goods-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "仓库商品",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": _vm.$route.query.category_id ? '' : '筛选'
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.$route.query.category_id ? function () {} : _vm.handleFilter();
      }
    }
  }), _c('div', {
    staticClass: "app-search-bar"
  }, [_c('form', {
    attrs: {
      "action": "/"
    }
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入商品名称",
      "clearable": ""
    },
    on: {
      "search": _vm.handleSearch
    },
    model: {
      value: _vm.listQuery.name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  })], 1)]), _c('div', {
    staticClass: "app-main-search-container list-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getGoodses
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.goodses, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container"
    }, [_c('warehouse-goods-item', {
      attrs: {
        "goods": goods
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(goods);
        }
      }
    })], 1);
  }), 0)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.selectAction
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  }), _c('my-dialog', {
    attrs: {
      "title": "筛选"
    },
    on: {
      "confirm": _vm.filter
    },
    model: {
      value: _vm.showFilterView,
      callback: function callback($$v) {
        _vm.showFilterView = $$v;
      },
      expression: "showFilterView"
    }
  }, [_c('van-checkbox-group', {
    staticClass: "check-group",
    model: {
      value: _vm.listQuery.types,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "types", $$v);
      },
      expression: "listQuery.types"
    }
  }, [_c('van-checkbox', {
    staticClass: "checkbox",
    attrs: {
      "name": 1
    }
  }, [_vm._v("隐藏禁止销售的")]), _c('van-checkbox', {
    staticClass: "checkbox",
    attrs: {
      "name": 2
    }
  }, [_vm._v("显示没储位的")]), _c('van-checkbox', {
    attrs: {
      "name": 3
    }
  }, [_vm._v("显示正在盘点的")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }