<template>
  <div class="goods" @click="$emit('click')">
    <van-cell v-if="goods.last_stocktaking_datetime" title="最后盘点时间" :value="goods.last_stocktaking_datetime" />
    <van-cell v-if="goods.stocktaking_user" title="正在盘点" :value="goods.stocktaking_user.name" />
    <div class="goods-info-container">
      <div class="goods-info-container__left">
        <van-image :src="goods.product.image" />
      </div>
      <div class="goods-info-container__right">
        <div class="goods-name">
          {{ goods.product.name }}
        </div>
        <div class="size">
          规格：1 * {{ goods.product.size }}
        </div>
        <div class="storage">
          储位：{{ goods.default_in_warehouse ? goods.default_in_warehouse.storage : '' }}
        </div>
        <div class="stock" :class="{stock_warning: goods.book_stock <= goods.stock_warning}">
          库存：{{ goods.book_stock }}{{ goods.product.least_unit }}
        </div>
      </div>
    </div>
    <van-cell v-if="canShowPrice" class="price-cell" title="查看价格" is-link @click.stop.prevent="goods.show_price = !goods.show_price">
      <template v-if="goods.show_price" #label>
        <span>
          来货价：￥{{ goods.product.purchase_price }}、
          批发价：￥{{ goods.product.whole_price }}、
          零售价：￥{{ goods.product.bulk_price }}、
          优惠价：￥{{ goods.product.prefrt_price }}</span>
      </template>
    </van-cell>
  </div>
</template>

<script>
export default {
  name: 'WarehouseGoodsItem',
  props: {
    goods: {
      type: Object,
      required: true
    }
  },
  computed: {
    canShowPrice() {
      return this.$store.state.userInfo.type === 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/color.scss';
  .goods-info-container {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px;
    &__left {
      .van-image {
        width: 80px;
        height: 80px;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      width: 100%;
      .goods-name {
        // margin-bottom: 8px;
      }
      .size, .storage, .stock {
        font-size: 13px;
        color: $grayTextColor;
      }
      .stock_warning {
        color: $amountRedColor;
      }
    }
  }
  .goods .price-cell::before {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0.42667rem;
    top: 0;
    left: 0.42667rem;
    border-top: 0.02667rem solid #ebedf0;
    transform: scaleY(.5);
  }
</style>
